import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import "./style.css";
import { Backdrop, CircularProgress, Grid, Typography } from "@mui/material";
import EventIcon from "@mui/icons-material/Event";
import Button from "@mui/material/Button";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import Patrocinadores from "../patrocinio/Patrocinadores";
import Conferencistas from "../conferencistas/Conferencistas";
import { Modal } from "@mui/material";
const admin_service = require("../../helpers/admin_service");
const helpers = require("../../helpers/helpers");

const Inicio = () => {
  const [contador] = useState(0);
  const [evento, setEvento] = useState({});
  const [patrocinadores, setPatrocinadores] = useState({});
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90vw",
    height: "90vh",
    bgcolor: "transparent",

    p: 4,
  };
  useEffect(() => {
    admin_service
      .getData("/evento/view-by-hash/" + process.env.REACT_APP_EVT)
      .then((response_evt) => {
        admin_service
          .getData("/patrocinador/view-by-evento/" + process.env.REACT_APP_EVT)
          .then((response_patrocinador) => {
            setEvento(response_evt.data.response_database.result[0]);
            setPatrocinadores(response_patrocinador.data.response.result);
            setLoading(false);
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  }, [contador]);
  return (
    <Box paddingTop={10}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Stack spacing={4} alignItems="center">
        <Stack spacing={5} alignItems="center" justifyContent="center">
          <Typography variant="h4" style={{ textAlign: "center" }}>
            <EventIcon /> &nbsp;7 de Agosto
          </Typography>
          <Button
            style={{
              backgroundColor: "#d1a131",
              color: "white",
              border: "1px solid transparent",
            }}
            variant="outlined"
            startIcon={<DriveFileRenameOutlineIcon />}
            onClick={() => {
              window.open(
                "https://ebg.edu.gt/inscripcion/f395e96f345932aee5c281f72c9640d4bc4faaccf8d125d5d3f652dcacf7829b",
                "_blank"
              );
            }}
          >
            Inscríbete en Línea
          </Button>
        </Stack>

        {/*<video autoPlay muted loop style={{ width: "50%" }}>
          <source
            src="https://escuela-bancaria.s3.us-east-2.amazonaws.com/2024+CLADIT+3.mp4"
            type="video/mp4"
          />
        </video>*/}
        <Stack alignItems="center" justifyContent="center" spacing={5}>
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            style={{ textAlign: "justify" }}
          >
            {/*<Grid xs={12} md={6} lg={6} p={3}>
              <Stack spacing={5}>
                <Button
                  onClick={handleTogglePlay}
                  startIcon={isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
                  style={{
                    backgroundColor: "#397d51",
                    color: "white",
                    border: "1px solid transparent",
                  }}
                >
                  {isPlaying
                    ? "Click para pausar video"
                    : "Click para reproducir video"}
                </Button>
                <video ref={videoRef} controls style={{ width: "100%" }}>
                  <source
                    src="https://escuela-bancaria.s3.us-east-2.amazonaws.com/Video+Jorge+Gonzalez++2.mp4"
                    type="video/mp4"
                  />
                </video>
              </Stack>
            </Grid>*/}
            {evento.planos && (
              <img
                src={evento.planos}
                alt={evento.hash}
                style={{ width: "90%" }}
                loading="lazy"
                onClick={handleOpen}
              />
            )}
            <Grid xs={12} md={6} lg={6} p={3}>
              <Typography variant="h4">¿Qué es COREMIF?</Typography>
              <Typography variant="p">
                <br />
                La Asociación Bancaria de Guatemala -ABG-, a través de sus
                iniciativas educativas, se dedica a fortalecer el entendimiento
                y las prácticas en torno a las remesas, las microfinanzas y la
                inclusión financiera. Este evento busca reunir a expertos y
                profesionales del sector para debatir y analizar los desafíos y
                oportunidades que enfrentan las economías locales y regionales
                en estos ámbitos.
                <br />
                <br />
                Para ello, invita a participar en su&nbsp;
                <strong>
                  XIX CONGRESO REGIONAL DE REMESAS, MICROFINANZAS E INCLUSIÓN
                  FINANCIERA 2024.
                </strong>
                &nbsp;Este congreso está diseñado para profesionales,
                académicos, y cualquier persona interesada en el desarrollo
                económico y social a través de las remesas y las microfinanzas,
                así como en la promoción de la inclusión financiera. Durante el
                evento, se presentarán conferencias nacionales e
                internacionales, paneles de discusión, y se proporcionarán
                espacios para el análisis y la reflexión con expertos en temas
                relacionados.
                <br />
                <br />
              </Typography>
            </Grid>
          </Grid>
          <Grid container alignItems="center" justifyContent="center">
            <Grid xs={12} md={6} lg={6} style={{ textAlign: "center" }}>
              <Typography variant="h4">HOTEL BARCELÓ</Typography>
              <Typography variant="h5">Salón el Dorado</Typography>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3861.025167691274!2d-90.5215387242587!3d14.597641777147818!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8589a3da4d796741%3A0x67a03ec10722b685!2sBarcel%C3%B3%20Guatemala%20City!5e0!3m2!1ses!2sgt!4v1711994358472!5m2!1ses!2sgt"
                width="75%"
                height="450"
                style={{ border: 0 }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </Grid>
            <Grid xs={12} md={6} lg={6} style={{ textAlign: "center" }}>
              <img
                src="https://escuela-bancaria.s3.us-east-2.amazonaws.com/BGUA_VIEW_01.jpg"
                style={{ width: "60%", loading: "lazy" }}
              />
            </Grid>
          </Grid>
          <ImageList cols={5}>
            <ImageListItem>
              <img
                src={`https://escuela-bancaria.s3.us-east-2.amazonaws.com/5b1900b9-b7ac-4159-b489-0bb2ac1f03a1.jpg`}
                alt="corain-img"
                style={{ width: "100%", objectFit: "cover" }}
                loading="lazy"
              />
            </ImageListItem>
            <ImageListItem>
              <img
                src={`https://escuela-bancaria.s3.us-east-2.amazonaws.com/01733751_gp.jpg`}
                alt="corain-img"
                style={{ width: "100%", objectFit: "cover" }}
                loading="lazy"
              />
            </ImageListItem>
            <ImageListItem>
              <img
                src={`https://escuela-bancaria.s3.us-east-2.amazonaws.com/BGUA_WED_29.webp`}
                alt="corain-img"
                style={{ width: "100%", objectFit: "cover" }}
                loading="lazy"
              />
            </ImageListItem>
            <ImageListItem>
              <img
                src={`https://escuela-bancaria.s3.us-east-2.amazonaws.com/BGC%2B-%2BEL%2BDORADO%2BMONTAJE.jpg`}
                alt="corain-img"
                style={{ width: "100%", objectFit: "cover" }}
                loading="lazy"
              />
            </ImageListItem>
            <ImageListItem>
              <img
                src={`https://escuela-bancaria.s3.us-east-2.amazonaws.com/129852.jpg`}
                alt="corain-img"
                style={{ width: "100%", objectFit: "cover" }}
                loading="lazy"
              />
            </ImageListItem>
          </ImageList>
        </Stack>
        <Conferencistas />
        <Grid container alignItems="center" justifyContent="center">
          <Grid xs={12} md={6} lg={6}>
            <img
              style={{ width: "100%" }}
              src={`https://escuela-bancaria.s3.us-east-2.amazonaws.com/IMG_7454+(2).jpg`}
              alt="cladit-img"
              loading="lazy"
            />
          </Grid>
          <Grid xs={12} md={6} lg={6}>
            <Stack spacing={2} style={{ padding: "6%" }}>
              <Typography variant="h5" style={{ textAlign: "center" }}>
                Dirigido a:
              </Typography>
              <Typography
                variant="p"
                style={{ textAlign: "left", fontSize: "0.9em" }}
              >
                <ul className="columns">
                  <li>Directores y Gerentes Generales</li>
                  <li>Directores y Gerentes de Microfinanzas</li>
                  <li>Directores y Gerentes de Inclusión Financiera</li>
                  <li>Directores y Gerentes de Operaciones</li>
                  <li>Directores y Gerentes de Riesgo</li>
                  <li>
                    Directores y Gerentes de Productos y Servicios Financieros
                  </li>
                  <li>Directores y Gerentes de Remesas</li>
                  <li>Directores y Gerentes de Educación Financiera</li>

                  <li>Directores y Gerentes de Relaciones Institucionales</li>
                  <li>Asesores y Consultores Financieros</li>
                  <li>Analistas Financieros y de Riesgo</li>
                  <li>Oficiales de Crédito y Microcrédito</li>
                  <li>Coordinadores de Proyectos de Inclusión Financiera</li>
                  <li>
                    Representantes de Instituciones de Regulación Financiera
                  </li>
                  <li>
                    Representantes de Organismos Internacionales y de
                    Cooperación
                  </li>
                  <li>
                    Representantes de ONGs y Asociaciones de Desarrollo
                    Económico
                  </li>
                  <li>Representantes de Cooperativas de Ahorro y Crédito</li>
                  <li>Representantes de Instituciones de Microfinanzas</li>
                  <li>Emprendedores y Empresarios en el Sector Financiero</li>
                </ul>
              </Typography>
            </Stack>
          </Grid>
        </Grid>
        {patrocinadores.length > 0 && (
          <Patrocinadores
            evt={process.env.REACT_APP_EVT}
            patrocinadores={patrocinadores}
          />
        )}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <img
              src={evento.planos}
              alt={evento.hash}
              style={{ width: "100%", objectFit: "contain", height: "100%" }}
              loading="lazy"
            />
          </Box>
        </Modal>
      </Stack>
    </Box>
  );
};

export default Inicio;

{
  /*<Box component="section" className="video-section">
          <Grid container></Grid>
          <video autoPlay muted loop id="myVideo">
            <source
              src="https://escuela-bancaria.s3.us-east-2.amazonaws.com/RESUMEN+CLADIT+2023.mp4"
              type="video/mp4"
            />
          </video>
  </Box>*/
}
