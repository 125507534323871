import React, { useEffect, useState } from "react";
import {
  Backdrop,
  Box,
  Card,
  CardContent,
  CardMedia,
  CircularProgress,
  CssBaseline,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import { autoPlay } from "react-swipeable-views-utils";
import { useTheme } from "@mui/material/styles";
import SwipeableViews from "react-swipeable-views";
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const admin_service = require("../../helpers/admin_service");
const helpers = require("../../helpers/helpers");
const Conferencistas = () => {
  const [speakers, setSpeakers] = useState([]);
  const [cargando, setCargando] = useState(true);
  const [contador] = useState(0);
  useEffect(() => {
    admin_service
      .getDataAuth("/conferencista/read/" + process.env.REACT_APP_EVT)
      .then((response) => {
        setSpeakers(helpers.chunkArray(response.data.response.result, 1));
        setCargando(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [contador]);
  const [cambios, setCambios] = useState(0);
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  useEffect(() => {}, [cambios]);
  const handleStepChange = (step) => {
    setActiveStep(step);
    setCambios(cambios + 1);
  };

  return (
    <Stack style={{ width: "100%" }} spacing={4}>
      <CssBaseline />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={cargando}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Typography variant="h4" textAlign="center">
        Conferencistas
      </Typography>
      <AutoPlaySwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
        interval={5000}
      >
        {speakers.map((actual, index) => (
          <Box
            alignItems="center"
            justifyContent="center"
            key={index}
            style={{ width: "100%" }}
          >
            {Math.abs(activeStep - index) <= 2 ? (
              <Grid container justifyContent="center">
                {actual.map((speaker, indice) => (
                  <Grid
                    xs={12}
                    md={12}
                    lg={12}
                    item
                    key={`speaker-${indice}`}
                    style={{
                      backgroundColor: "transparent",
                    }}
                  >
                    <Card
                      style={{
                        width: "100%",
                        backgroundColor: "transparent",
                      }}
                    >
                      <CardMedia
                        component="img"
                        style={{
                          width: "100%",
                          height: "500px",
                          objectFit: "contain",
                        }}
                        image={speaker.foto}
                        alt={`logo ${speaker.nombre}`}
                      />
                      <CardContent style={{ textAlign: "center" }}>
                        <Typography gutterBottom variant="h5" component="div">
                          {speaker.nombre}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {speaker.puesto}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {speaker.institucion}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {speaker.pais}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            ) : null}
          </Box>
        ))}
      </AutoPlaySwipeableViews>
    </Stack>
  );
};

export default Conferencistas;
